import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import AgeVerification from './components/main_screens/AgeVerification';
import RewardViewer from './components/main_screens/prize_viewer';
import {base} from './base';

function AuthenticatedRoute({component: Component, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if(authenticated === true){
                    return (
                        <Component {...props} {...rest} />
                    )
                } else {
                    return (
                        <Redirect to={{pathname: '/login', state: {from: props.location}}} />
                    )
                }
            }} />
    )
}

class App extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true,
            passedEmail: false
        }
    }

    setCurrentUser() {
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    componentDidMount() {
        this.getTenantVariables();
    }

    getTenantVariables(){
        this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
            then(variables){
                document.title = variables.pageTitle || "Reveal";
                this.loadFont();
                this.setState({
                    authenticated: false,
                    variables: variables,
                    loading: false
                })
            }
        });
    }

    async loadFont(){
        await import('../src/styles/css/font.env.css');
    };

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    setPassedEmail(isSet=true){
      this.setState({
          passedEmail: isSet
      })
    }

    checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age;
        try {
            user_age = sessionStorage.getItem('verifiedAge')
        } catch (e) {
            user_age = false;
        }
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        return (user_age && user_age >= variable_age) || formBirthday;
    }

    render() {
        if (this.state.loading === true) {
            return (
                <Loading backgroundImage={this.state.variables.backgroundImage} primaryColor={this.state.variables.primaryColor || "#000"}/>
            )
        }
        return (
            <BrowserRouter>
              <Switch>
                <Route
                    exact
                    path="/login"
                    render={(props) => {
                        return <Login authenticated={this.state.authenticated} setCurrentUser={this.setCurrentUser} variables={this.state.variables} setPassed={(setPassed)=>this.setPassedEmail(setPassed)} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />

              <AuthenticatedRoute
                  exact
                  path="/"
                  authenticated={this.state.authenticated}
                  variables={this.state.variables}
                  passedEmail={this.state.passedEmail}
                  checkForAgeGate={this.checkForAgeGate}
                  component={MainApp} />

              <Route
                  exact
                  path="/prizeviewer"
                  render={(props) => {
                      return <RewardViewer {...props} />
                  }}
              />

                <Route
                    exact
                    path="/age_gate"
                    render={(props) => {
                        return <AgeVerification variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />

                <Redirect to='/login'/>
              </Switch>
            </BrowserRouter>
        )
    }
}

export default App;
